<template>
    <y-loading
        :active="$wait.is('loading-page')"
        height="calc( 100vh - 200px )"
    >
        <y-page-head
            :title="title"
            icon="md-truck"
        >
            <template slot="actions">
                <!-- Save -->
                <y-button
                    color="green"
                    form="first-form"
                    aria-label="Save"
                    loading-on="submitting-form"
                >
                    {{ $t('button.save') }}
                </y-button>
                <!-- Create new user -->
                <y-button
                    color="info"
                    aria-label="New User"
                    loading-on="submitting-form"
                    @click.prevent="$refs.arenaCreateForm.openModal()"
                >
                    {{ $t('person.new_user.create.new_user') }}
                </y-button>
            </template>
        </y-page-head>

        <main>
            <y-row>
                <y-col
                    sm="10"
                    center
                >
                    <form
                        id="first-form"
                        class="arena-form"
                        @submit.prevent="submit(saveForm)"
                    >
                        <y-create-form
                            ref="arenaCreateForm"
                            v-model="model"
                        />
                    </form>
                </y-col>
            </y-row>
        </main>
    </y-loading>
</template>

<script>

    // Mixins
    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';

    import YCreateForm from '@/modules/arena/components/Create';

    export default {

        name: 'OrderForm',

        components: {
            YCreateForm,
        },

        mixins: [PageMixin, FormMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc('arena-create', this.$t('arena.title.create')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.title,
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: {},
            };
        },

        computed: {

            /**
             * Page title
             */
            title() {
                if (this.$route.params.id) {
                    return this.$t('arena.title.edit');
                }
                return this.$t('arena.title.create');
            },

            /**
             * today date
             */
            todayDate() {
                const today = new Date();
                const date = `${today.getFullYear()} / ${today.getMonth() + 1} / ${today.getDate()}`;
                return date;
            },

        },

        methods: {
            /**
             * Save form
             */
            saveForm() {
                return this.$services.Arena.orderSave(this.model).then((res) => {
                    this.$router.push({ name: 'arena-details', params: { id: res.data.metadata.hashid } });
                    this.$toast.success(this.$t('messages.save.success', { type: this.title }));
                }).catch(((error) => {
                    this.handleError(error);
                }));
            },
        },

    };
</script>
