/*-----------------------------------------------------------------
- Register nodes
-----------------------------------------------------------------*/
import BinderNode from './BinderNode';
import NoteNode from './NoteNode';
import ChangeStatusNode from './ChangeStatusNode';

export default {
    BinderNode,
    NoteNode,
    ChangeStatusNode,
};
