<template>
    <div>
        <y-timeline-node
            v-bind="nodeBind"
            alert
            color="blue"
            icon="md-pencil"
        >
            <div
                v-if="node.data.model"
                class="ltr"
            >
                <div class="row">
                    <div class="col-sm-4">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('arena.node.border_name') }}
                            </div>
                            <div
                                v-if="node.data.model.border_name"
                                class="value"
                            >
                                {{ node.data.model.border_name }}
                            </div>
                            <div
                                v-else
                                class="value"
                            >
                                {{ `-` }}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('arena.node.destination_custom') }}
                            </div>
                            <div
                                v-if="node.data.model.destination_custom"
                                class="value"
                            >
                                {{ node.data.model.destination_custom }}
                            </div>
                            <div
                                v-else
                                class="value"
                            >
                                {{ `-` }}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('arena.node.arrived_at') }}
                            </div>
                            <div
                                v-if="node.data.model.arrived_at"
                                class="value"
                            >
                                {{ node.data.model.arrived_at | date('YYYY/MM/DD', $i18n.locale) }}
                            </div>
                            <div
                                v-else
                                class="value"
                            >
                                {{ `-` }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </y-timeline-node>
    </div>
</template>

<script>
    import TimelineNode from '@/mixins/TimelineNode';

    export default {
        name: 'ArrivalReportNode',

        mixins: [TimelineNode],
    };
</script>
