<template>
    <div>
        <table class="table bordered">
            <thead>
                <tr>
                    <td class="color-gray ta-c">
                        {{ `${nowDate()} :${$t('arena.forms.date')}` }}
                    </td>
                    <td class="color-gray ta-c">
                        <y-form-field
                            ref="order_user_search"
                            v-model="query"
                            type="select"
                            name="order_user_search"
                            :placeholder="$t('arena.forms.person_placeholder')"
                            :options="userList"
                            :endpoint="{
                                name : 'endpoint-model-list',
                                params: {
                                    search : query,
                                    including: ['id','name_full'],
                                    model: 'user'
                                },
                            }"
                            label-field="name_full"
                            value-field="id"
                            search="search"
                            allow-empty
                            no-label
                            validation="required"
                            @select="selectUser"
                        />
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="color-gray ta-c">
                        <y-form-field
                            ref="order_participants_search"
                            v-model="model.participants"
                            type="select"
                            name="order_participants_search"
                            :placeholder="$t('arena.forms.person_placeholder')"
                            :options="participantsList"
                            :endpoint="{
                                name : 'endpoint-model-list',
                                params: {
                                    search : participantsQuery,
                                    including: ['id','name_full'],
                                    model: 'user'
                                },
                            }"
                            label-field="name_full"
                            value-field="id"
                            search="search"
                            allow-empty
                            no-label
                            multi
                            @select="selectParticipants"
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{ $t('arena.forms.fields.participants') }}
                    </th>
                </tr>
                <tr>
                    <td>
                        <y-form-field
                            v-model="model.consignee_name"
                            type="text"
                            no-label
                            class="mb0"
                            name="consignee_name"
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{ $t('arena.forms.fields.consignee_name') }}
                    </th>
                </tr>
                <tr>
                    <td>
                        <y-form-field
                            v-model="model.shipping_type"
                            type="select"
                            :options="shipperOption"
                            label-field="title"
                            value-field="slug"
                            validation="required"
                            no-label
                            name="shipping_type"
                            :placeholder="$t('arena.forms.shipping_type_placeholder')"
                            dir="ltr"
                            class="mb0"
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{ $t('arena.forms.fields.shipping_type') }}
                    </th>
                </tr>
                <tr>
                    <td class="d-f flex-row-reverse align-items-center">
                        <span
                            v-if="model.shipping_type && !isEdit"
                            class="mr10 mt5 fw-b"
                        >{{ `${model.shipping_type}` }}</span>

                        <y-form-field
                            v-model="model.ref_no"
                            type="text"
                            no-label
                            name="ref_no"
                            validation="required"
                            class="mb0"
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{ $t('arena.forms.fields.ref_no') }}
                    </th>
                </tr>
                <tr>
                    <td>
                        <y-form-field
                            v-model="model.truck_no"
                            type="text"
                            name="truck_no"
                            validation="required"
                            no-label
                            class="mb0"
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{ $t('arena.forms.fields.truck_no', [$t('arena.forms.fields.truck')]) }}
                    </th>
                </tr>
                <tr>
                    <td>
                        <y-form-field
                            v-model="model.shipper"
                            type="text"
                            name="shipper"
                            no-label
                            class="mb0"
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{ $t('arena.forms.fields.shipper') }}
                    </th>
                </tr>
                <tr>
                    <td>
                        <y-form-field
                            v-model="model.loading_place"
                            type="text"
                            name="loading_place"
                            no-label
                            class="mb0"
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{ $t('fields.loading_place') }}
                    </th>
                </tr>
                <tr>
                    <td>
                        <y-form-date-picker
                            v-model="model.shipped_at"
                            name="shipped_at"
                            validation="required"
                            :calendar="['gregorian']"
                            no-label
                            class="mb0"
                            locale="en"
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{ $t('arena.forms.fields.shipped_date') }}
                    </th>
                </tr>
                <tr>
                    <td>
                        <y-form-field
                            v-model="model.packages_number"
                            type="number"
                            name="packages_number"
                            no-label
                            class="mb0"
                            locale="en"
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{ $t('arena.forms.fields.packages_number') }}
                    </th>
                </tr>
                <tr>
                    <td>
                        <y-form-field
                            v-model="model.weight"
                            type="number"
                            name="weight"
                            no-label
                            class="mb0"
                            locale="en"
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{ $t('arena.forms.fields.weight') }}
                    </th>
                </tr>
                <tr>
                    <td>
                        <y-form-field
                            v-model="model.l_c_no"
                            type="text"
                            name="l_c_no"
                            no-label
                            class="mb0"
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{ $t('arena.forms.fields.l_c_no') }}
                    </th>
                </tr>
                <tr>
                    <td>
                        <y-form-field
                            v-model="model.b_l_no"
                            type="text"
                            name="b_l_no"
                            no-label
                            class="mb0"
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{ $t('arena.forms.fields.b_l_no') }}
                    </th>
                </tr>
                <tr>
                    <td>
                        <y-form-field
                            v-model="model.border_name"
                            type="text"
                            name="border_name"
                            no-label
                            class="mb0"
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{ $t('arena.forms.fields.border_name') }}
                    </th>
                </tr>
                <tr>
                    <td>
                        <y-form-field
                            v-model="model.destination_custom"
                            type="text"
                            name="destination_custom"
                            no-label
                            class="mb0"
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{ $t('arena.forms.fields.destination_custom') }}
                    </th>
                </tr>
            </tbody>
        </table>
        <div class="notification mt30">
            <y-form-field
                v-model="model.notification_form"
                type="checkbox"
                :label="$t('arena.forms.notification.form')"
            />
            <y-form-field
                v-model="model.notification_transit"
                type="checkbox"
                :label="$t('arena.forms.notification.transit')"
            />
            <y-form-field
                v-model="model.notification_documents"
                :disabled="!model.notification_form"
                type="checkbox"
                :label="$t('arena.forms.notification.documents')"
            />
        </div>
        <y-modal ref="modal">
            <!-- Create New User -->
            <div class="p10">
                <div class="panel p10 d-f justify-content-between align-items-center">
                    <span>
                        {{ $t('person.new_user.create.new_user') }}
                    </span>
                </div>
                <div class="panel p10">
                    <y-form-field
                        v-model="CreateUserModel.name_first"
                        type="text"
                        name="name_first"
                        :placeholder="$t('arena.forms.name_first')"
                    />
                    <y-form-field
                        v-model="CreateUserModel.email"
                        type="email"
                        name="email"
                    />
                    <y-form-field
                        v-model="CreateUserModel.mobile"
                        type="mobile"
                        name="mobile"
                        format="(DDDD) DDD DDDD"
                    />
                </div>
            </div>

            <template slot="footer">
                <div class="w-100 d-f align-items-center justify-content-end">
                    <y-button
                        type="reset"
                        @click.prevent.native="$refs.modal.close()"
                    >
                        {{ $t('button.cancel') }}
                    </y-button>
                    <y-button
                        type="submit"
                        class="mr5"
                        color="green"
                        loading-on="submitting-user"
                        @click.prevent="submitUser"
                    >
                        {{ $t('button.submit') }}
                    </y-button>
                </div>
            </template>
        </y-modal>
    </div>
</template>

<script>

    import { YFormField, YFormDatePicker, YModal } from '@deps';
    import moment from 'moment-jalaali';

    export default {

        name: 'CreateForms',

        components: {
            YModal,
            YFormField,
            YFormDatePicker,
        },

        props: {
            /**
             * Form type
             */
            type: String,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model                   : {},
                value                   : {},
                CreateUserModel         : {},
                selectedUser            : null,
                selectedParticipant     : null,
                query                   : null,
                participantsQuery       : null,
                participantsListResource: [],
                searched                : false,
                shipperOption           : [
                    {
                        slug : 'AT',
                        title: this.$t('arena.shipper_option.AT'),
                    },
                    {
                        slug : 'AR',
                        title: this.$t('arena.shipper_option.AR'),
                    },
                    {
                        slug : 'AC',
                        title: this.$t('arena.shipper_option.AC'),
                    },
                ],
            };
        },

        computed: {
            /**
             * Check if is edit mode
             */
            isEdit() {
                return !!this.$route.params.id;
            },

            /**
             * get user list
             */
            userList() {
                return this.model.user ? [this.model.user] : [];
            },

            /**
             * get participants list
             */
            participantsList() {
                return this.model.participants && this.model.participants.length ? this.participantsListResource : [];
            },
        },

        watch: {
            value: {
                /**
                 * Handle watch value
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', { ...this.model, ...newVal });
                    }
                },
                deep: true,
            },

            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    if (this.model.notification_form === 0) {
                        this.model.notification_documents = 0;
                    }

                    this.$emit('input', this.model);
                },

                deep: true,
            },
        },

        /**
         * @inheritdoc
         */
        created() {
            if (this.isEdit) {
                this.fetch();
            }
        },

        methods: {
            /**
             * Add new user
             *
             * @param user
             */
            addNewUser(user) {
                this.users.push(user);
                this.$set(this.model, 'id', user.id);
            },

            /**
             * Return current date
             */
            nowDate() {
                return moment().format('jYYYY/jM/jD');
            },

            /**
             * Select User from the result
             * 
             * @param {object} user - selected user
             */
            selectUser(user) {
                this.$set(this, 'selectedUser', user);
                this.$set(this.model, 'user_id', user.id);
            },

            /**
             * Select User from the result
             * 
             * @param {object} user - selected user
             */
            selectParticipants(user) {
                this.$set(this, 'selectedParticipant', user);
                this.model.participants.push(user.id);
            },

            /**
             * Open modal
             */
            openModal() {
                this.$refs.modal.open();
            },

            /**
             * Create new user
             */
            submitUser() {
                const params = {
                    role      : 'customer',
                    modal_mode: true,
                    ...this.CreateUserModel,
                };

                this.$wait.start('submitting-user');
                return this.$services.Person.usersPanelSave(params).then(() => {
                    this.$toast.success(this.$t('person.toast.save.success'));
                    this.$refs.modal.close();
                }).catch(((error) => {
                    this.handleError(error);
                })).finally(() => {
                    this.$wait.end('submitting-user');
                });
            },
            
            /**
             * Fetch fields
             *
             * @returns {*}
             */
            fetch() {
                const params = { id: this.isEdit ? this.$route.params.id : '' };
                return this.$services.Arena.orderFetch(params).then((response) => {
                    this.participantsListResource = response.data.results.participants_list;
                    this.model = {
                        b_l_no            : response.data.results.b_l_no,
                        border_name       : response.data.results.border_name,
                        destination_custom: response.data.results.destination_custom,
                        id                : response.data.results.id,
                        l_c_no            : response.data.results.l_c_no,
                        loading_place     : response.data.results.loading_place,
                        packages_number   : response.data.results.packages_number,
                        ref_no            : response.data.results.ref_no,
                        shipped_at        : response.data.results.shipped_at,
                        shipper           : response.data.results.shipper,
                        shipping_type     : response.data.results.shipping_type,
                        truck_no          : response.data.results.truck_no,
                        user_id           : response.data.results.user_id,
                        weight            : response.data.results.weight,
                        participants      : response.data.results.participants || [],
                    };
                    if (this.isEdit && response.data.results?.user) {
                        this.query = response.data.results.user;
                    }
                }).catch(((error) => {
                    this.handleError(error);
                }));
            },
        },

    };
</script>
