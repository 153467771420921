<template>
    <y-timeline-node v-bind="nodeBind">
        <p
            v-if="data.old_label"
            class="mb0"
            v-html="$t('timeline.changeStatus.full', [data.old_label, data.new_label])"
        />

        <p
            v-else
            class="mb0"
            v-html="$t('timeline.changeStatus.new', [data.new_label])"
        />

        <p
            v-if="data.reason"
            class="mb0 small"
        >
            {{ data.reason }}
        </p>
    </y-timeline-node>
</template>

<script>

    import TimelineNodeMixin from '@/mixins/TimelineNode';

    export default {
        name: 'ChangeStatusNode',

        mixins: [TimelineNodeMixin],

        computed: {

            /**
             * Return label with wrapper
             */
            label() {
                return `<span class="fw-b">${this.data.new_label}</span>`;
            },

        },

    };

</script>
