<template>
    <div>
        <y-timeline-node
            v-bind="nodeBind"
            alert
            color="blue"
            icon="md-pencil"
        >
            <div
                v-if="node.data.model"
                class="ltr"
            >
                <div class="row">
                    <div class="col-sm-3">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('arena.node.ref_no') }}
                            </div>
                            <div
                                v-if="node.data.model.ref_no"
                                class="value"
                            >
                                {{ node.data.model.ref_no }}
                            </div>
                            <div
                                v-else
                                class="value"
                            >
                                {{ `-` }}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('arena.node.shipper') }}
                            </div>
                            <div
                                v-if="node.data.model.shipper"
                                class="value"
                            >
                                {{ node.data.model.shipper }}
                            </div>
                            <div
                                v-else
                                class="value"
                            >
                                {{ `-` }}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('arena.node.truck_no') }}
                            </div>
                            <div
                                v-if="node.data.model.truck_no"
                                class="value"
                            >
                                {{ node.data.model.truck_no | digits('en') }}
                            </div>
                            <div
                                v-else
                                class="value"
                            >
                                {{ `-` }}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('arena.node.shipped_at') }}
                            </div>
                            <div
                                v-if="node.data.model.shipped_at"
                                class="value"
                            >
                                {{ node.data.model.shipped_at | date('YYYY/MM/DD', 'en') }}
                            </div>
                            <div
                                v-else
                                class="value"
                            >
                                {{ `-` }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-3">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('arena.node.b_l_no') }}
                            </div>
                            <div
                                v-if="node.data.model.b_l_no"
                                class="value"
                            >
                                {{ node.data.model.b_l_no | digits('en') }}
                            </div>
                            <div
                                v-else
                                class="value"
                            >
                                {{ `-` }}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('arena.node.l_c_no') }}
                            </div>
                            <div
                                v-if="node.data.model.l_c_no"
                                class="value"
                            >
                                {{ node.data.model.l_c_no | digits('en') }}
                            </div>
                            <div
                                v-else
                                class="value"
                            >
                                {{ `-` }}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('arena.node.border_name') }}
                            </div>
                            <div
                                v-if="node.data.model.border_name"
                                class="value"
                            >
                                {{ node.data.model.border_name }}
                            </div>
                            <div
                                v-else
                                class="value"
                            >
                                {{ `-` }}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('arena.node.weight') }}
                            </div>
                            <div
                                v-if="node.data.model.weight"
                                class="value"
                            >
                                {{ node.data.model.weight | digits('en') }}
                            </div>
                            <div
                                v-else
                                class="value"
                            >
                                {{ `-` }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-3">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('arena.node.destination_custom') }}
                            </div>
                            <div
                                v-if="node.data.model.destination_custom"
                                class="value"
                            >
                                {{ node.data.model.destination_custom | digits('en') }}
                            </div>
                            <div
                                v-else
                                class="value"
                            >
                                {{ `-` }}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('arena.node.packages_number') }}
                            </div>
                            <div
                                v-if="node.data.model.packages_number"
                                class="value"
                            >
                                {{ node.data.model.packages_number | digits('en') }}
                            </div>
                            <div
                                v-else
                                class="value"
                            >
                                {{ `-` }}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('arena.node.loading_place_title') }}
                            </div>
                            <div
                                v-if="node.data.model.loading_place"
                                class="value"
                            >
                                {{ node.data.model.loading_place | digits('en') }}
                            </div>
                            <div
                                v-else
                                class="value"
                            >
                                {{ `-` }}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="detail-item" />
                    </div>
                </div>
            </div>
        </y-timeline-node>
    </div>
</template>

<script>
    import TimelineNode from '@/mixins/TimelineNode';

    export default {
        name: 'ArenaOrderEditedNode',

        mixins: [TimelineNode],
    };
</script>
