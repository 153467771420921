<template>
    <div>
        <y-timeline-node
            v-bind="nodeBind"
            alert
            color="blue"
            icon="md-pencil"
        >
            <div v-if="node.data.model">
                <p class="mb-0">
                    {{ node.data.model.text }}
                </p>
            </div>
        </y-timeline-node>
    </div>
</template>

<script>
    import TimelineNode from '@/mixins/TimelineNode';

    export default {
        name: 'OrderClosedNode',

        mixins: [TimelineNode],
    };
</script>
