<template>
    <y-loading
        :active="$wait.is('loading-page')"
        height="calc( 100vh - 200px )"
    >
        <y-page-head
            :title="title"
            icon="md-truck"
        >
            <template slot="actions">
                <y-button
                    id="refresh-button"
                    type="button"
                    icon="md-refresh"
                    aria-label="Refresh"
                    @click.prevent="reFetch"
                />
                <y-button
                    type="button"
                    aria-label="Save"
                    color="blue"
                    loading-on="submitting-form"
                    @click.prevent="saveForm"
                >
                    {{ $t('button.save') }}
                </y-button>
                <y-button
                    v-if="downloadLinkCheck"
                    id="download_file"
                    type="button"
                    aria-label="Download"
                    color="blue"
                    tag="a"
                    rel="noopener noreferrer"
                    :target="downloadLink.redirect.target"
                    :href="downloadLink.redirect.url"
                >
                    {{ $t('button.download') }}
                </y-button>
                <y-button
                    v-else
                    type="button"
                    aria-label="Download"
                    color="green"
                    loading-on="download-form"
                    @click.prevent="downloadForm"
                >
                    {{ $t('button.excel') }}
                </y-button>
            </template>
        </y-page-head>

        <main v-if="formFields">
            <y-row>
                <y-col
                    sm="10"
                    center
                >
                    <y-form
                        :type="formType"
                        :value="formFields"
                        @change="model = { ...model, ...$event }"
                    />

                    <template v-if="formType === 'arrival'">
                        <p v-html="$t(`arena.forms.footer.${formFields.shipping_type === 'AC' ? 'ac_description' : 'at_description'}`)" />
                        <div class="d-f align-items-center color-gray mb10">
                            <span
                                class="w-100 d-f whs-nw ml15"
                                v-html="$t(`arena.forms.footer.${formFields.shipping_type === 'AC' ? 'ac_cost' : 'at_cost'}`)"
                            />
                            <y-form-field
                                v-model="model.price"
                                type="number"
                                no-label
                                class="mb0"
                                format="separated"
                            />
                            <span
                                class="w-100 mr15 whs-nw"
                                v-html="$t(`arena.forms.footer.rial`)"
                            />
                        </div>

                        <template v-if="formFields.shipping_type === 'AC'">
                            <div
                                class="d-f align-items-center color-gray mb10"
                            >
                                <span
                                    class="w-100 d-f whs-nw ml15"
                                    v-html="$t('arena.forms.footer.cheque.on_cost')"
                                />
                                <y-form-field
                                    v-model="model.ac_price"
                                    type="number"
                                    no-label
                                    class="mb0"
                                    format="separated"
                                />
                                <span
                                    class="w-100 mr15 whs-nw"
                                    v-html="$t('arena.forms.footer.cheque.rial')"
                                />
                            </div>
                            <div
                                class="d-f align-items-center color-gray mb10"
                            >
                                <span
                                    class="w-100 d-f whs-nw ml15"
                                    v-html="$t('arena.forms.footer.cheque.on_cost')"
                                />
                                <y-form-field
                                    v-model="model.ac_price_second"
                                    type="number"
                                    no-label
                                    class="mb0"
                                    format="separated"
                                />
                                <span
                                    class="w-100 mr15 whs-nw"
                                    v-html="$t('arena.forms.footer.cheque.rial')"
                                />
                            </div>
                            <p v-html="$t('arena.forms.footer.cheque.description')" />
                        </template>
                    </template>

                    <div class="notification mt30">
                        <y-form-field
                            v-model="model.notification_form"
                            type="checkbox"
                            :label="$t('arena.forms.notification.form')"
                        />
                        <y-form-field
                            v-model="model.notification_transit"
                            type="checkbox"
                            :label="$t('arena.forms.notification.transit')"
                        />
                        <y-form-field
                            v-model="model.notification_documents"
                            type="checkbox"
                            :label="$t('arena.forms.notification.documents')"
                        />
                    </div>
                </y-col>
            </y-row>
        </main>
    </y-loading>
</template>

<script>

    // Mixins
    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';

    import YForm from '@/modules/arena/components/Form';
    import { YFormField } from '@deps';

    export default {

        name: 'Forms',

        components: {
            YForm,
            YFormField,
        },

        mixins: [PageMixin, FormMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc('arena-forms', this.$t(`arena.forms.${this.$route.params.type}.title`)),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.title,
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                formFields       : null,
                model            : {},
                downloadLink     : null,
                downloadFileType : null,
                downloadLinkCheck: false,
            };
        },

        computed: {
            /**
             * Page title
             */
            title() {
                if (!this.formType) {
                    return '';
                }
                return this.$t(`arena.forms.${this.formType}.title`);
            },

            /**
             * Form type
             */
            formType() {
                return this.$route.params.type;
            },
        },

        watch: {
            /**
             * Check download link
             *
             * @param newValue
             * @param oldValue
             */
            downloadLink(newValue, oldValue) {
                if (oldValue !== newValue) {
                    this.downloadLinkCheck = true;
                } else {
                    this.downloadLinkCheck = false;
                }
            },
        },

        methods: {
            /**
             * Fetch fields
             *
             * @returns {*}
             */
            fetch() {
                const params = { id: this.$route.params.id };
                return this.$services.Arena.orderFetch(params).then((response) => {
                    this.formFields = response.data.results;
                }).catch(((error) => {
                    this.handleError(error);
                }));
            },

            /**
             * Save form
             *
             * @returns {Promise}
             */
            saveForm() {
                this.$wait.start('submitting-form');
                const params = { id: this.$route.params.id, ...this.model };
                return this.$services.Arena[this.formType === 'arrival' ? 'arrivalReport' : 'borderReport'](params).then(() => {
                    this.$toast.success(this.$t('messages.save.success', { type: this.title }));
                }).catch(((error) => {
                    this.handleError(error);
                })).finally(() => {
                    this.$wait.end('submitting-form');
                });
            },

            /**
             * download form
             *
             * @returns {Promise}
             */
            downloadForm() {
                this.$wait.start('download-form');
                if (this.formType === ' border') {
                    this.downloadFileType = 'border_report';
                } else if (this.formType === 'arrival') {
                    this.downloadFileType = 'arrival_report';
                } else {
                    this.downloadFileType = 'order_details';
                }
                const params = {
                    id  : this.$route.params.id,
                    form: this.downloadFileType,
                };
                return this.$services.Arena.orderExcelDownload(params).then((response) => {
                    this.downloadLink = response.data.results;
                    this.$toast.success(this.$t('arena.excel_massage', { type: this.title }));
                }).catch(((error) => {
                    this.handleError(error);
                })).finally(() => {
                    this.$wait.end('download-form');
                    document.getElementById('download_file').textContent = this.$t('button.download');
                });
            },
        },

    };
</script>
