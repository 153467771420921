<template>
    <y-timeline-node v-bind="nodeBind">
        <y-form
            v-if="form"
            :params="form"
        />
    </y-timeline-node>
</template>

<script>

    import TimelineNodeMixin from '@/mixins/TimelineNode';
    import { YForm } from '@deps';

    export default {
        name: 'BinderNode',

        components: {
            YForm,
        },

        mixins: [TimelineNodeMixin],

    };

</script>
