/*-----------------------------------------------------------------
- Timeline Node mixin
-----------------------------------------------------------------*/
import YTimelineNode from '@deps/TimelineNode';

export default {
    
    components: {
        YTimelineNode,
    },
    
    props: {
        
        node: Object,
        
        title: String,
        
        icon: String,
        
        color: String,
        
        at: [Number, String],
        
        by: String,
        
        data: Object,
        
        form: Array,
        
        isAlert: Boolean,
        
        hasHood: Boolean,
        
    },
    
    computed: {
        
        /**
         * Return object to bind to timeline node
         */
        nodeBind() {
            return {
                node   : this.node,
                title  : this.title,
                icon   : this.icon,
                color  : this.color,
                data   : this.data,
                form   : this.form,
                isAlert: this.isAlert,
                hasHood: this.hasHood,
                at     : this.at,
                by     : this.by,
            };
        },
        
    },
    
};
