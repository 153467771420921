<template>
    <div>
        <div
            class="timeline-node node"
            :class="[color, {'alert-node': isAlert}]"
        >
            <header class="node-header">
                <div class="right">
                    <span
                        v-if="icon"
                        class="icon"
                        :class="[icon]"
                    />
                    <div class="title">
                        {{ title }}
                        <span
                            v-if="node.data.is_private"
                            class="label private-note__label"
                        >
                            {{ $t('general.timeline.private_note') }}
                        </span>
                    </div>
                </div>
                <div class="left">
                    <div class="details">
                        <div
                            class="details-meta"
                            :class="{ 'ml10': $slots.details || hasHood }"
                        >
                            <span v-if="at">{{ at | date('YYYY/M/D - HH:mm', $i18n.locale) | digits }}</span>
                            <span v-if="by">{{ $t('general.timeline.by', [by]) }}</span>
                        </div>

                        <slot name="details">
                            <y-button
                                v-if="hasHood"
                                size="xs"
                                @click.native.prevent="$refs.Modal.open(node.id)"
                            >
                                {{ $t('button.details') }}
                            </y-button>
                        </slot>
                    </div>
                </div>
            </header>

            <div
                v-if="$slots.default"
                class="node-content"
            >
                <slot />
            </div>

            <div
                v-if="node.data.order_status || node.data.is_by_admin"
                class="d-f justify-content-end align-items-center"
            >
                <span class="node-note__status">{{ $t('general.timeline.add_in_status', [node.data.order_status]) }}</span>
                <span
                    v-if="node.data.is_by_admin"
                    class="node-note__admin"
                    :data-tooltip="$t('general.timeline.by_admin')"
                >
                    <i class="icon md-account" />
                </span>
            </div>
        </div>

        <y-timeline-node-modal
            v-if="hasHood"
            ref="Modal"
            v-slot="data"
            :icon="icon"
            :color="color"
            :size="size"
        >
            <slot
                name="modal"
                :content="data.content"
            />
        </y-timeline-node-modal>
    </div>
</template>

<script>

    export default {

        name: 'TimelineNode',

        components: {
            YTimelineNodeModal: () => import('@deps/TimelineNodeModal'),
        },

        props: {

            node: Object,

            icon: String,

            title: String,

            at: [String, Number],

            by: String,

            isAlert: Boolean,

            hasHood: Boolean,

            color: String,

            size: String,

        },

    };
</script>
