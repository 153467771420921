<template>
    <div class="panel-timeline">
        <div
            v-if="!Array.isArray(timeline)"
            class="nodes"
        >
            <transition-group name="fade">
                <template v-for="node in timeline">
                    <component
                        :is="availableNodes[node.type || node.class]"
                        v-if="(node.type || node.class) in availableNodes"
                        :key="`node-${node.id}`"
                        :class="node.class"

                        :node="node"
                        :icon="node.icon"
                        :title="node.title"
                        :color="node.color"
                        :at="get(node, 'created_at')"
                        :by="get(node, 'created_by.full_name')"
                        :data="getNodeData(node)"
                        :form="node.form"
                        :is-alert="!!get(node, 'is_alert')"
                        :has-hood="!!get(node, 'has_hood')"
                    />

                    <y-empty-state
                        v-else
                        :key="`node-${node.id}`"
                        height="50"
                        no-image
                        :message="$t('general.timeline.node_not_found', [node.class])"
                    />
                </template>
            </transition-group>
        </div>

        <y-empty-state
            v-else
            height="250"
            class="mb0 mt20"
        />
    </div>
</template>

<script>

    import DefaultNodes from '@deps/nodes';
    import YEmptyState from '@deps/EmptyState';

    export default {

        name: 'Timeline',

        components: {
            YEmptyState,
        },

        props: {

            nodes: {
                type   : Object,
                default: () => {},
            },

            timeline: [Object, Array],

        },

        computed: {

            /**
             * @inheritDoc
             */
            availableNodes() {
                return { ...DefaultNodes, ...this.nodes };
            },

        },

        methods: {

            /**
             * Get node data with correct default value
             *
             * @param node
             * @returns {*}
             */
            getNodeData(node) {
                const { data } = node;

                return Array.isArray(data) ? {} : data;
            },

        },

    };

</script>
