<template>
    <div>
        <y-timeline-node
            v-bind="nodeBind"
            alert
            color="blue"
            icon="md-pencil"
        >
            <div
                v-if="node.data.model"
                class="ltr"
            >
                <div class="row">
                    <div class="col-sm-6">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('arena.node.arrival_place') }}
                            </div>
                            <div
                                v-if="node.data.model.arrival_place"
                                class="value"
                            >
                                {{ node.data.model.arrival_place }}
                            </div>
                            <div
                                v-else
                                class="value"
                            >
                                {{ `-` }}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('arena.node.shipping_type') }}
                            </div>
                            <div
                                v-if="node.data.model.shipping_type"
                                class="value"
                            >
                                {{ node.data.model.shipping_type }}
                            </div>
                            <div
                                v-else
                                class="value"
                            >
                                {{ `-` }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-3">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('arena.node.price') }}
                            </div>
                            <div
                                v-if="node.data.model.price"
                                class="value"
                            >
                                {{ node.data.model.price | digits }}
                            </div>
                            <div
                                v-else
                                class="value"
                            >
                                {{ `-` }}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('arena.node.ac_price') }}
                            </div>
                            <div
                                v-if="node.data.model.ac_price"
                                class="value"
                            >
                                {{ node.data.model.ac_price | digits }}
                            </div>
                            <div
                                v-else
                                class="value"
                            >
                                {{ `-` }}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('arena.node.ac_price_second') }}
                            </div>
                            <div
                                v-if="node.data.model.ac_price_second"
                                class="value"
                            >
                                {{ node.data.model.ac_price_second | digits }}
                            </div>
                            <div
                                v-else
                                class="value"
                            >
                                {{ `-` }}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="detail-item" />
                    </div>
                </div>
            </div>
        </y-timeline-node>
    </div>
</template>

<script>
    import TimelineNode from '@/mixins/TimelineNode';

    export default {
        name: 'BorderReportNode',

        mixins: [TimelineNode],
    };
</script>
