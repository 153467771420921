<template>
    <y-loading
        :active="$wait.is('loading-page')"
        height="calc( 100vh - 200px )"
    >
        <y-page-head
            :title="title"
            icon="md-truck"
        >
            <template slot="actions">
                <y-button
                    id="refresh-button"
                    type="button"
                    icon="md-refresh"
                    aria-label="Refresh"
                    @click.prevent="reFetch"
                />

                <y-button
                    v-if="details"
                    loading-on="changeStatusLoading"
                    @click.prevent="changeStatus"
                >
                    {{ details.is_closed ? $t('arena.details.button.open_order') : $t('arena.details.button.close_order') }}
                </y-button>

                <y-button
                    tag="router-link"
                    :to="formsRoute('arrival')"
                >
                    {{ $t('arena.details.button.arrival') }}
                </y-button>

                <y-button
                    v-if="details && details.shipping_type === 'AT'"
                    tag="router-link"
                    :to="formsRoute('border')"
                >
                    {{ $t('arena.details.button.border') }}
                </y-button>

                <y-button
                    tag="router-link"
                    :to="formsRoute('edit')"
                >
                    {{ $t('arena.details.button.edit') }}
                </y-button>
            </template>
        </y-page-head>

        <main v-if="details">
            <div class="row">
                <div class="col-sm-8">
                    <y-panel
                        :title="$t('arena.details.order.title')"
                        icon="md-account-box"
                        class="mb0"
                    >
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="detail-item">
                                    <div class="title">
                                        {{ $t('arena.details.order.ref_no') }}
                                    </div>
                                    <div
                                        v-if="details.ref_no"
                                        class="value"
                                    >
                                        {{ details.ref_no }}
                                    </div>
                                    <div
                                        v-else
                                        class="value"
                                    >
                                        {{ `-` }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="detail-item">
                                    <div class="title">
                                        {{ $t('arena.details.order.status') }}
                                    </div>
                                    <div class="value">
                                        <span
                                            class="label"
                                            :class="details.is_closed ? 'red' : 'green'"
                                        >
                                            {{ $t(`arena.details.order.status_label.${details.is_closed ? 'close' : 'open'}`) }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6">
                                <div class="detail-item">
                                    <div class="title">
                                        {{ $t('arena.details.order.user_name') }}
                                    </div>
                                    <div class="value">
                                        <router-link :to="routeToProfile(details.user.id)">
                                            {{ details.user.name_full }}
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="detail-item">
                                    <div class="title">
                                        {{ $t('arena.details.order.email') }}
                                    </div>
                                    <div
                                        v-if="details.user.email"
                                        class="value"
                                    >
                                        <y-form-email-label
                                            :content="details.user.email"
                                            copy
                                        />
                                    </div>
                                    <div
                                        v-else
                                        class="value"
                                    >
                                        {{ `-` }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-6">
                                <div class="detail-item">
                                    <div class="title">
                                        {{ $t('arena.details.order.shipping_type') }}
                                    </div>
                                    <div
                                        v-if="details.shipping_type_title"
                                        class="value"
                                    >
                                        {{ details.shipping_type_title }}
                                    </div>
                                    <div
                                        v-else
                                        class="value"
                                    >
                                        {{ `-` }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="detail-item">
                                    <div class="title">
                                        {{ $t('arena.details.order.effected_at') }}
                                    </div>
                                    <div
                                        v-if="details.effected_at"
                                        class="value"
                                    >
                                        {{ details.effected_at | date('YYYY/MM/DD', $i18n.locale) }}
                                    </div>
                                    <div
                                        v-else
                                        class="value"
                                    >
                                        {{ `-` }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </y-panel>

                    <y-timeline
                        :nodes="RegisteredNodes"
                        :timeline="details.timeline"
                    />
                </div>

                <div class="col-sm-4">
                    <y-panel
                        :title="$t('arena.details.comment.title')"
                        :class="{disabled: $wait.is('submitting-comment')}"
                    >
                        <!-- Comment -->
                        <form
                            id="comment-form"
                            @submit.prevent="saveComment"
                        >
                            <y-form-field
                                id="comment"
                                v-model="note"
                                type="textarea"
                                name="comment"
                                no-label
                            />

                            <div class="ta-l">
                                <y-button
                                    color="blue"
                                    loading-on="submitting-comment"
                                    form="comment-form"
                                >
                                    {{ $t('button.submit') }}
                                </y-button>
                            </div>
                        </form>
                    </y-panel>
                </div>
            </div>
        </main>
    </y-loading>
</template>

<script>

    // Mixins
    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';

    // import { date } from '@nodes/helpers/date';

    import YFormField from '@deps/form/FormField';
    import YFormEmailLabel from '@deps/form/elements/EmailLabel';
    import YTimeline from '@deps/Timeline';
    import RegisteredNodes from '@/modules/arena/components/nodes';

    export default {

        name: 'ArenaOrderDetails',

        components: {
            YFormField,
            YFormEmailLabel,
            YTimeline,
        },

        mixins: [PageMixin, FormMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc('arena-details', this.$t('breadcrumbs.details')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('arena.title.details'),
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                RegisteredNodes,

                details: null,
                form   : null,
                model  : {},
                note   : null,
            };
        },

        computed: {
            /**
             * Page title
             */
            title() {
                return this.$t('arena.title.details');
            },
        },

        methods: {
            /**
             * Fetch Details
             *
             * @returns {*}
             */
            fetch() {
                const params = { id: this.$route.params.id };
                return this.$services.Arena.orderDetails(params).then((response) => {
                    this.details = response.data.results;
                }).catch(((error) => {
                    this.handleError(error);
                }));
            },

            /**
             * Save Comment
             */
            saveComment() {
                this.$wait.start('submitting-comment');
                const params = {
                    text: this.note,
                    id  : this.$route.params.id,
                };
                return this.$services.Arena.orderComment(params).then(() => {
                    this.$toast.success(this.$t('arena.details.msg.comment'));
                    this.note = null;
                    this.fetch();
                }).catch(((error) => {
                    this.handleError(error);
                })).finally(() => this.$wait.end('submitting-comment'));
            },

            /**
             * Change order status
             */
            changeStatus() {
                this.$wait.start('changeStatusLoading');
                const params = {
                    id    : this.$route.params.id,
                    status: this.details.is_closed === 1 ? 0 : 1,
                };
                return this.$services.Arena.orderStatusChange(params).then(() => {
                    this.$toast.success(this.$t('arena.details.msg.status'));
                    this.reFetch();
                }).catch(((error) => {
                    this.handleError(error);
                })).finally(() => this.$wait.end('changeStatusLoading'));
            },

            /**
             * Route to Profile
             * 
             * @param {string} id
             */
            routeToProfile(id) {
                return {
                    name  : 'persons-users-profile',
                    params: {
                        id,
                    },
                };
            },

            /**
             * Route to Forms
             * 
             * @param {string} type
             */
            formsRoute(type) {
                return {
                    name  : 'arena-forms',
                    params: {
                        id: this.$route.params.id,
                        type,
                    },
                };
            },
        },

    };
</script>
