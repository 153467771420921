<template>
    <y-timeline-node
        v-bind="nodeBind"
        :class="{'private-note': data.is_private}"
    >
        <div v-html="data.text" />
    </y-timeline-node>
</template>

<script>

    import TimelineNodeMixin from '@/mixins/TimelineNode';

    export default {
        name: 'NoteNode',

        mixins: [TimelineNodeMixin],

    };

</script>
