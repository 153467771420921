/*-----------------------------------------------------------------
- Register nodes
-----------------------------------------------------------------*/
import ArenaOrderCreatedNode from './OrderCreatedNode';
import ArenaOrderEditedNode from './OrderEditedNode';
import ArrivalReportNode from './ArrivalReportNode';
import BorderReportNode from './BorderReportNode';
import OrderClosedNode from './OrderClosedNode';
import OrderReopenedNode from './OrderReopenedNode';

export default {
    ArenaOrderCreatedNode,
    ArenaOrderEditedNode,
    ArrivalReportNode,
    BorderReportNode,
    OrderClosedNode,
    OrderReopenedNode,
};
