<template>
    <div v-if="value">
        <table class="table bordered">
            <thead>
                <tr>
                    <td class="color-gray ta-c">
                        {{ `${nowDate()} :${$t('arena.forms.date')}` }}
                    </td>
                    <td
                        v-if="value && value.user"
                        class="color-gray ta-c"
                    >
                        {{ `${value.user.name_full} :${$t('arena.forms.consignee')}` }}
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="color-gray ta-c">
                        <y-form-field
                            ref="order_participants_search"
                            v-model="model.participants"
                            type="select"
                            name="order_participants_search"
                            :placeholder="$t('arena.forms.person_placeholder')"
                            :options="participantsList"
                            :endpoint="{
                                name : 'endpoint-model-list',
                                params: {
                                    search : participantsQuery,
                                    including: ['id','name_full'],
                                    model: 'user'
                                },
                            }"
                            label-field="name_full"
                            value-field="id"
                            search="search"
                            allow-empty
                            no-label
                            multi
                            @select="selectParticipants"
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{ $t('arena.forms.fields.participants') }}
                    </th>
                </tr>
                <tr>
                    <td class="readonly">
                        <y-form-field
                            type="text"
                            :value="value.consignee_name"
                            no-label
                            class="mb0"
                            disabled
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{ $t('arena.forms.fields.consignee_name') }}
                    </th>
                </tr>
                <tr>
                    <td class="readonly">
                        <y-form-field
                            type="text"
                            :value="value.ref_no"
                            no-label
                            class="mb0"
                            disabled
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{ $t('arena.forms.fields.ref_no') }}
                    </th>
                </tr>
                <tr>
                    <td class="readonly">
                        <y-form-field
                            type="text"
                            :value="value.truck_no"
                            no-label
                            class="mb0"
                            disabled
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{
                            $t('arena.forms.fields.truck_no',
                               [$t(`arena.forms.fields.${nameState('shipping_type', ['container', 'truck'])}`)])
                        }}
                    </th>
                </tr>
                <tr>
                    <td class="readonly">
                        <y-form-field
                            type="text"
                            :value="value.shipper"
                            no-label
                            class="mb0"
                            disabled
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{ $t('arena.forms.fields.shipper') }}
                    </th>
                </tr>
                <tr>
                    <td class="readonly">
                        <y-form-field
                            type="text"
                            :value="value.loading_place"
                            no-label
                            class="mb0"
                            disabled
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{
                            $t('arena.forms.fields.loading_place',
                               [$t(`arena.forms.fields.${nameState('shipping_type', ['port', 'place'])}`)])
                        }}
                    </th>
                </tr>
                <tr v-if="type === 'arrival'">
                    <td class="readonly">
                        <y-form-field
                            v-model="model.arrival_place"
                            type="text"
                            no-label
                            class="mb0"
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{
                            $t('arena.forms.fields.arrival_place',
                               [$t(`arena.forms.fields.${nameState('shipping_type', ['port', 'place'])}`)])
                        }}
                    </th>
                </tr>
                <tr v-else>
                    <td class="readonly">
                        <y-form-date-picker
                            v-model="model.arrived_at"
                            type="date"
                            no-label
                            class="mb0"
                            :calendar="['gregorian']"
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{ $t('arena.forms.fields.arrived_at') }}
                    </th>
                </tr>
                <tr>
                    <td class="readonly">
                        <y-form-field
                            type="text"
                            :value="value.packages_number"
                            no-label
                            class="mb0"
                            disabled
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{ $t('arena.forms.fields.packages_number') }}
                    </th>
                </tr>
                <tr>
                    <td class="readonly">
                        <y-form-field
                            type="text"
                            :value="value.weight"
                            no-label
                            class="mb0"
                            disabled
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{ $t('arena.forms.fields.weight') }}
                    </th>
                </tr>
                <tr>
                    <td class="readonly">
                        <y-form-field
                            type="text"
                            :value="value.l_c_no"
                            no-label
                            class="mb0"
                            disabled
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{ $t('arena.forms.fields.l_c_no') }}
                    </th>
                </tr>
                <tr>
                    <td class="readonly">
                        <y-form-field
                            type="text"
                            :value="value.b_l_no"
                            no-label
                            class="mb0"
                            disabled
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{ $t('arena.forms.fields.b_l_no') }}
                    </th>
                </tr>
                <tr>
                    <td class="readonly">
                        <y-form-date-picker
                            v-model="model.loaded_at"
                            type="date"
                            no-label
                            class="mb0"
                            :calendar="['gregorian']"
                        />
                    </td>
                    <th class="color-gray whs-nw dir-ltr ta-l">
                        {{ $t('arena.forms.fields.loaded_at') }}
                    </th>
                </tr>
                <template v-if="type === 'border'">
                    <tr>
                        <td class="readonly">
                            <y-form-field
                                v-model="model.border_name"
                                type="text"
                                no-label
                                class="mb0"
                            />
                        </td>
                        <th class="color-gray whs-nw dir-ltr ta-l">
                            {{ $t('arena.forms.fields.border_name') }}
                        </th>
                    </tr>
                    <tr>
                        <td class="readonly">
                            <y-form-field
                                v-model="model.destination_custom"
                                type="text"
                                no-label
                                class="mb0"
                            />
                        </td>
                        <th class="color-gray whs-nw dir-ltr ta-l">
                            {{ $t('arena.forms.fields.destination_custom') }}
                        </th>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>

    import { YFormField, YFormDatePicker } from '@deps';
    import moment from 'moment-jalaali';

    export default {

        name: 'Forms',

        components: {
            YFormField,
            YFormDatePicker,
        },

        props: {
            /**
             * Form type
             */
            type: String,

            value: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model                   : {},
                participantsQuery       : null,
                participantsListResource: [],
            };
        },

        computed: {
            /**
             * get participants list
             */
            participantsList() {
                return this.model.participants && this.model.participants.length ? this.participantsListResource : [];
            },
        },

        watch: {
            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('change', this.model);
                },

                deep: true,
            },

            value: {
                /**
                 * Set default value in model
                 *
                 * @param newValue
                 */
                handler(newValue) {
                    this.participantsListResource = newValue.participants_list;
                    if (this.$route.params.type === 'arrival') {
                        this.model = {
                            ...this.model,
                            participants   : newValue.participants || [],
                            arrival_place  : newValue.arrival_place,
                            price          : newValue.price,
                            ac_price       : newValue.ac_price,
                            ac_price_second: newValue.ac_price_second,
                            loaded_at      : newValue.loaded_at,
                        };
                    } else {
                        this.model = {
                            ...this.model,
                            participants      : newValue.participants || [],
                            arrived_at        : newValue.arrived_at,
                            border_name       : newValue.border_name,
                            destination_custom: newValue.destination_custom,
                            loaded_at         : newValue.loaded_at,
                        };
                    }
                },

                deep     : true,
                immediate: true,
            },
        },

        methods: {
            /**
             * Select User from the result
             * 
             * @param {object} user - selected user
             */
            selectParticipants(user) {
                this.$set(this, 'selectedParticipant', user);
                this.model.participants.push(user.id);
            },
            
            /**
             * Return true state of name 
             *
             * @param key
             * @param value
             */
            nameState(key, value) {
                return this.value[key] === 'AC' ? value[0] : value[1];
            },

            /**
             * Return current date
             */
            nowDate() {
                return moment().format('jYYYY/jM/jD');
            },
        },

    };
</script>
